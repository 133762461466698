@use 'fonts/font_sizes' as *;

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(inline-font-file($thin)) format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(inline-font-file($regular)) format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url(inline-font-file($light-bold)) format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(inline-font-file($bold)) format('woff2');
}
